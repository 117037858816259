:root {
  --bs-primary-rgb: 130, 7, 0;
  --bs-secondary-rgb: 88, 71, 63;

}

#root {
  min-height: 100vh;
  padding-bottom: 20vh;
  position: relative;
}

footer {
  height: 161.8px
}

.btn-outline-primary {
  --bs-btn-color: #820700;
  --bs-btn-border-color: #820700;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #820700;
  --bs-btn-hover-border-color: #820700;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #820700;
  --bs-btn-active-border-color: #820700;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #820700;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #820700;
  --bs-gradient: none;
}

.btn:hover {
  background-color: var(--bs-btn-hover-bg) !important;
  border-color: var(--bs-btn-hover-border-color);
  color: var(--bs-btn-hover-color);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #820700;
  --bs-btn-border-color: #820700;
  --bs-btn-hover-color: #820700;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #820700;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #820700;
  --bs-btn-active-border-color: #820700;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #820700;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #820700;
}