.form-check-input:checked {
    color: #820700 !important;
    background-color: #820700!important;
    border-color: #820700!important;
    border-top-color: rgb(33, 37, 41)!important;
    border-right-color: rgb(33, 37, 41)!important;
    border-bottom-color: rgb(33, 37, 41)!important;
}

.form-check-input:focus {
    border-color: #820700!important;
    outline: 0!important;
    box-shadow: 0 0 0 .25rem rgba(130, 7, 0, .25)!important;
}