.fade-spin {
    transition: opacity .25s ease-in-out, transform .25s ease-in-out;
}

.fade-spin.fade-out {
    opacity: 0.15;
    transform: rotate(-90deg);
}

/* .fade-spin.fade-in {
    opacity: 1;
    transform: rotate(0deg);
}
 */
