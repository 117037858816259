/* Scrollbar styles */
#product_list::-webkit-scrollbar {
    height: 6px;
    /* Adjust the height to make the scrollbar thin */
}

#product_list::-webkit-scrollbar-thumb {
    background: #820700;
    /* Color of the scrollbar thumb */
    border-radius: 10px;
    /* Rounded corners */
}

#product_list::-webkit-scrollbar-thumb:hover {
    background: #820700;
    /* Color when hovered */
}

#product_list::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Color of the scrollbar track */
}