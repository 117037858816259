.overlay {
    height: 100vh;
    position: fixed;
    top: 0px;
    width: 100%;
    /* z-index: 999999999; */
    /* border-top: 1px solid rgba(0,0,0,.75); */
}

.overlay-open {
    opacity: 1;
    transition: .5s ease-in-out;
    right: 100%;
    transform: translate(100%, 0);
}

.overlay-close {
    opacity: 1;
    transition: .5s ease-in-out;
    /* z-index: -1; */
    right: -100%;
}

ul {
    top: 90px;
}

