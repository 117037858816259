.carousel-indicators [data-bs-target] {
    text-indent: 0!important;
    width: auto!important;
    height: auto!important;
    flex: 0 0 auto!important;

}

.carousel-indicators {
    position: relative !important;
    margin-left: 0!important;
    margin-right: 0!important;
}


/* Scrollbar styles */
.carousel-indicators::-webkit-scrollbar {
    height: 6px;
    /* Adjust the height to make the scrollbar thin */
}

.carousel-indicators::-webkit-scrollbar-thumb {
    background: #820700;
    /* Color of the scrollbar thumb */
    border-radius: 10px;
    /* Rounded corners */
}

.carousel-indicators::-webkit-scrollbar-thumb:hover {
    background: #820700;
    /* Color when hovered */
}

.carousel-indicators::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Color of the scrollbar track */
}